const SELECTOR_ITEM = '.js-jki-dropdown-nav-item';
const SELECTOR_SUB = '.js-jki-dropdown-nav-sub';
const CLASS_ITEM_ACTIVATION_BUTTON = 'jki-dropdown-nav-item__button';
const CLASS_ITEM_IS_OPEN = 'js-jki-dropdown-nav-item-open';

const LINK_SELECTOR = 'a';
const ESC_KEY = 27;

let autoCloseOnTabOutTimer;

function createItemActivationButton({label, onClick}) {
	const button = document.createElement('button');
	button.setAttribute('type', 'button');
	button.setAttribute('aria-expanded', 'false');
	button.className = CLASS_ITEM_ACTIVATION_BUTTON;
	button.innerHTML = `
		<svg height="3" width="6" class="text-gray-500">
			<polygon points="0,0 6,0 3,3" class="fill-current"></polygon>
		</svg>
		<span class="sr-only">
			Untermenü ${label} ausklappen
		</span>
	`;
	button.addEventListener('click', onClick);

	return button;
}

function openElement(element) {
	const button = element.getElementsByClassName(CLASS_ITEM_ACTIVATION_BUTTON)[0];
	button.setAttribute('aria-expanded', 'true');
	button.addEventListener('keydown', escapeHandler);

	element.classList.add(CLASS_ITEM_IS_OPEN);

	const listItemElement = element.closest('li');

	const subElement = listItemElement.querySelector(SELECTOR_SUB);
	subElement.style.setProperty('display', 'block');
	subElement.addEventListener('keydown', escapeHandler);

	const subLinks = [...subElement.getElementsByTagName('a')];
	addAutoCloseEventsToLink(element.querySelector(LINK_SELECTOR));
	addAutoCloseEventsToLink(element);
	subLinks.forEach(addAutoCloseEventsToLink);
}

function closeElement(element) {
	const button = element.getElementsByClassName(CLASS_ITEM_ACTIVATION_BUTTON)[0];
	button.setAttribute('aria-expanded', 'false');
	button.removeEventListener('keydown', escapeHandler);

	element.classList.remove(CLASS_ITEM_IS_OPEN);

	const listItemElement = element.closest('li');

	const subElement = listItemElement.querySelector(SELECTOR_SUB);
	subElement.style.removeProperty('display');
	subElement.removeEventListener('keydown', escapeHandler);

	const subLinks = [...subElement.getElementsByTagName('a')];
	clearAutoCloseFocus();
	removeAutoCloseEventsToLink(element.querySelector(LINK_SELECTOR));
	removeAutoCloseEventsToLink(button);
	subLinks.forEach(removeAutoCloseEventsToLink);
}

function closeOpenElements() {
	[...document.getElementsByClassName(CLASS_ITEM_IS_OPEN)].forEach(closeElement);
}

function escapeHandler(event) {
	if (event.keyCode === ESC_KEY) {
		event.stopPropagation();

		const closestActiveElement = event.target.closest(SELECTOR_SUB) || event.target.closest(SELECTOR_ITEM);
		const buttonElement = closestActiveElement.closest('li').querySelector(`.${CLASS_ITEM_ACTIVATION_BUTTON}`);
		closeOpenElements();
		buttonElement.focus();
	}
}

function clearAutoCloseFocus() {
	if (autoCloseOnTabOutTimer) {
		clearTimeout(autoCloseOnTabOutTimer);
		autoCloseOnTabOutTimer = null;
	}
}

function onAutoCloseBlur() {
	autoCloseOnTabOutTimer = setTimeout(closeOpenElements, 10);
}

function addAutoCloseEventsToLink(link) {
	link.addEventListener('focus', clearAutoCloseFocus);
	link.addEventListener('blur', onAutoCloseBlur);
}

function removeAutoCloseEventsToLink(link) {
	link.removeEventListener('focus', clearAutoCloseFocus);
	link.removeEventListener('blur', onAutoCloseBlur);
}

export function render() {
	[...document.querySelectorAll(SELECTOR_ITEM)].forEach(element => {
		element.appendChild(createItemActivationButton({
			label: element.textContent,
			onClick: e => {
				e.preventDefault();
				e.stopPropagation();

				if (element.matches(`.${CLASS_ITEM_IS_OPEN}`)) {
					closeElement(element);
				} else {
					closeOpenElements();
					openElement(element);
				}
			},
		}));

		const linkElement = element.querySelector('a');
		linkElement.addEventListener('touchstart', e => {
			if (element.matches(`.${CLASS_ITEM_IS_OPEN}`)) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			closeOpenElements();
			linkElement.focus();
			openElement(element);
		});
	});
}

import throttle from 'lodash/throttle';
//import debounce from 'lodash/debounce';

let currentScrollPos = 0;
let prevScrollPos = 0;
let isTop = null;
let isUp = null;
let isDown = null;

let lockCounter = 0;

let listeners = [];

export function isLocked() {
	return lockCounter > 0;
}

export function lock() {
	lockCounter++;
}

export function unlock() {
	lockCounter--;
}

export function addListener(fn) {
	listeners.push(fn);
}

export function removeListener(fn) {
	listeners = listeners.filter(l => l !== fn);
}

function trigger() {
	listeners.forEach(l => l());
}

export const getScrollState = () => ({
	isTop: isTop,
	isUp: isUp,
	isDown: isDown,
});

function updateScrollAbsolute() {
	currentScrollPos = window.pageYOffset || 0;
	const newIsTop = currentScrollPos < 100;

	if (newIsTop !== isTop) {
		isTop = newIsTop;

		setTimeout(() => {
			document.body.classList[newIsTop ? 'add' : 'remove']('scrolled-top');
			document.body.classList[newIsTop ? 'remove' : 'add']('scrolled');
		}, 0);
		trigger();
	}
}

function updateScrollDirectional() {
	updateScrollAbsolute();

	const newIsUp = !isTop && (prevScrollPos > currentScrollPos && (isUp || (prevScrollPos - currentScrollPos > 70)));
	const newIsDown = !isTop && (prevScrollPos < currentScrollPos && (isDown || (currentScrollPos - prevScrollPos > 70)));

	if (!isLocked()) {
		let hasChanged = false;
		if (newIsUp !== isUp) {
			hasChanged = true;
			isUp = newIsUp;
			setTimeout(() => {
				document.body.classList[newIsUp ? 'add' : 'remove']('scrolled-up');
			}, 0);
		}

		if (newIsDown !== isDown) {
			hasChanged = true;
			isDown = newIsDown;
			setTimeout(() => {
				document.body.classList[newIsDown ? 'add' : 'remove']('scrolled-down');
			}, 0);
		}

		if (hasChanged) {
			trigger();
		}
	}

	prevScrollPos = currentScrollPos;
}

const handleDirectionalScroll = throttle(updateScrollDirectional, 100);
const handleScrollAbsolute = throttle(updateScrollAbsolute, 500, {leading: false, trailing: true});

const handleScroll = () => {
	handleDirectionalScroll();
	handleScrollAbsolute();
};

updateScrollAbsolute();
window.addEventListener('scroll', handleScroll, {passive: true});
